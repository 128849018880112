@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Fjalla+One&family=Teko:wght@300..700&display=swap");

$white: #f4f4f4;
$black: #1c1c1c;
$lightGray: #898989;
$green: #69e298;
$bluePurple: #723be5;
$purple: #824dd1;
$pink: #b94dd1;

html,
body {
    font-family: "Plus Jakarta Sans", sans-serif;
    background-color: $white !important;
    max-width: 100%;
    overflow-x: hidden;
    color: $black;
}

h2 {
    font-weight: 500 !important;
}

a {
    text-decoration: none !important;
    transition: 0.2s;

    &:hover {
        transition: 0.2s;
        cursor: pointer;
    }
}

.Header {
    .navbar-brand {
        img {
            height: 25px !important;
            width: auto;
        }
    }

    nav {
        backdrop-filter: blur(10px);
    }
}

.btn-ubives {
    background-color: $bluePurple;
    border-radius: 8px;
    color: $white;
    padding: 8px 20px;
    font-size: 14px;
    border: none;
    transition: 0.2s;
}

.btn-ubives:hover {
    background-color: $purple;
    transition: 0.2s;
}

.btn-ubives:active {
    background-color: #4c269e;
}

.btn-ubives:disabled {
    background-color: #713be569;
}

.Home {
    .landing {
        background-image: url('./assets/hero-background.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-position-y: -100px;
        color: $white;

        h1 {
            font-weight: 700;
            font-size: 50px;
            margin-top: 20vh;
            opacity: 0;
        }

        i {
            font-weight: 400;
            background-color: $white;
            border-radius: 8px;
            color: $bluePurple;
            padding: 2px 5px;
        }

        h2 {
            font-size: 20px;
            letter-spacing: 1.7px;
            font-weight: 300 !important;
            opacity: 0;
        }

        @media screen and (max-width: 767px) {
            h1 {
                font-size: 30px;
                line-height: 40px;
            }

            h2 {
                font-size: 16px;
                margin-bottom: 5vh;
            }
        }

        .video {
            border-radius: 20px;
            display: flex;
            overflow: hidden;
            position: relative;
            background-color: #fff;
            justify-content: center;
            align-items: center;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

            &:hover {
                cursor: pointer;
            }

            video {
                display: block;
                width: 100%;
                height: 100%;
            }

            .action-button {
                color: $bluePurple;
                width: 100px;
                height: 100px;
                position: absolute;
                box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
                border-radius: 100px;
                transform: scale(0);
            }
        }

        .early-box {
            div {
                background-color: $white;
                border-radius: 8px;
                padding: 20px;
                box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
            }

            h3, p {
                color: $black;
            }
        }
    }

    .row {
        height: 100%;
    }

    @media screen and (max-width: 767px) {
        .landing {
            height: 90vh;

            h1 {
                margin-top: 20vh;
            }

            .landing-element {
                margin: 10vh 7vh 10vh 10vh;
                transform: translateX(4vh);
                max-height: 40vh !important;
                width: fit-content;
            }
        }
    }

    #about {
        margin-top: 20vh;
        margin-bottom: 20vh !important;

        @media screen and (max-width: 767px) {
            margin-top: 10vh;
        }

        p {
            font-size: 20px;
            text-align: justify;
            font-weight: 500;
            font-style: italic;
        }

        @media screen and (max-width: 991px) {
            h2 {
                font-size: 100px;
            }
        }

        margin-bottom: 10vh;
    }

    #our-team {
        min-height: 140vh;
        background-color: $black;
        color: $white;
        padding-bottom: 10vh;
        padding-top: 10vh;

        h2 {
            font-size: 140px;
            padding-bottom: 10vh;
            color: $green;
        }

        @media screen and (max-width: 991px) {
            h2 {
                font-size: 70px;
            }
        }

        h3 {
            font-weight: 800;
            margin-top: 30px;
        }

        h4 {
            font-size: 18px;
            color: $pink;
            font-weight: 800;
            margin-top: 10px;
            margin-bottom: 20px;
        }

        .portrait {
            margin-top: 5vh;
            width: 200px;
            height: auto;
            border: 7px solid $pink;
            border-radius: 100px;
            transition: 0.5s;
        }

        .portrait:hover {
            box-shadow: 0 0 50px 2px #b94dd152;
            transition: 0.2s;
        }
    }

    #news {
        margin-top: 10vh;
        margin-bottom: 10vh;

        .news-box {
            border-radius: 16px;
            border: 1px solid lightgray;
            cursor: pointer;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
            margin-top: 5vh;

            img {
                border-radius: 16px 16px 0 0;
                width: 800px;
                height: 250px;
                object-fit: cover;
            }

            .wolves-img {
                object-position: 10%;
                object-fit: cover;
            }

            .news-desc {
                margin: 20px;
                height: 310px;
            }

            h3 {
                font-weight: 700;
            }
        }

        h2 {
            font-size: 140px;
        }

        @media screen and (max-width: 991px) {
            h2 {
                font-size: 100px;
            }
        }
    }

    #subscribe {
        .row {
            min-height: 70vh;
        }

        .bg-subscribe {
            background-image: url("./assets/bg-subscribe.png");
            background-size: contain;
        }

        h2 {
            font-family: "Bebas Neue", sans-serif;
            font-weight: 600;
            font-size: 72px;
            line-height: 60px;
        }

        a {
            color: $bluePurple !important;

            &:hover {
                color: $purple !important;
            }
        }
    }

    #our-partners, #awards {
        .eit-digital {
            height: 70px;
            object-fit: contain;
        }

        .kh-start-it {
            height: 70px;
            object-fit: contain;
        }

        .f6s {
            height: 70px;
            object-fit: contain;
            
            &:hover {
                cursor: pointer;
            }
        }

        h2 {
            font-size: 80px;
            font-weight: 600 !important;
        }

        @media screen and (max-width: 767px) {
            h2 {
                font-size: 50px;
            }
        }
    }

    footer {
        background-color: $black;
        color: $white;

        h2 {
            font-size: 18px;
            text-transform: uppercase;
            color: $lightGray;
            font-weight: 800;
        }

        a {
            color: $white !important;

            &:hover {
                color: $lightGray !important;
            }
        }

        .social-links {
            font-size: 24px;

            * {
                margin-right: 25px;
                transition: 0.1s;
            }

            *:hover {
                color: $lightGray;
                transition: 0.1s;
                cursor: pointer;
            }
        }

        .payment {
            background-color: $white;
            border-radius: 6px;
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 67px;
            height: 43px;

            img {
                width: 41px;
            }
        }

        .links {
            li {
                list-style-type: none;
                margin-left: -30px !important;
                margin-bottom: 10px;
            }

            a {
                text-decoration: none;
                color: $white;
                transition: ease-in-out 0.1s;
            }

            a:hover {
                color: $lightGray;
                transition: ease-in-out 0.1s;
            }
        }
    }
}

.Unsubscribe {
    padding-top: 10vh;
    background-color: $white;

    h1 {
        font-weight: 800;
    }
}

.container {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
}

.text-end {
    text-align: right;
}

.carousel-slide {
    display: flex !important;
    justify-content: center;
}

.carousel-slide-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.carousel-slide-content > div {
    flex: 1;
    margin: 0 10px;
}

.slick-arrow.slick-prev {
    color: $black;
    transform: translateX(-30px);

    &::after {
        width: 20px !important;
        height: 20px !important;
    }
}

.slick-arrow.slick-next {
    color: $black;
    transform: translateX(20px);

    &::after {
        width: 20px !important;
        height: 20px !important;
    }
}

.loading {
    position: fixed;
    width: 100vw !important;
    height: 100vh !important;
    background-color: #fff;
    z-index: 9999;
    
    img {
        width: 70px;
        height: auto;
    }
}